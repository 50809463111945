export const socialLinks = [
	{
		id: 2,
		name: 'GitHub',
		icon: 'github',
		url: 'https://github.com/s-saadali',
	},
	{
		id: 3,
		name: 'Twitter',
		icon: 'twitter',
		url: 'https://twitter.com/saadali77002964',
	},
	{
		id: 4,
		name: 'LinkedIn',
		icon: 'linkedin',
		url: 'www.linkedin.com/in/syed-saad-ali-3b192313b',
	},
	{
		id: 5,
		name: 'YouTube',
		icon: 'youtube',
		url: 'https://www.youtube.com/channel/UCu84baWaQB6hAippxji-PeA',
	},
];
